define("insights-web/services/stat-calculation", ["exports", "insights-web/utils/widgetstattypes", "insights-web/utils/agentstattypes"], function (_exports, _widgetstattypes, _agentstattypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    realtimeService: Ember.inject.service(),
    requestService: Ember.inject.service(),
    _formatTimeString: function _formatTimeString(duration) {
      //"00:00:00"

      duration = duration / 1000;
      //Ember.Logger.log(duration);

      var seconds = parseInt(duration % 60);
      var minutes = parseInt(duration / 60 % 60);
      var hours = parseInt(duration / (60 * 60) % 24);
      if (isNaN(seconds)) {
        return "";
      }
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      return hours + ":" + minutes + ":" + seconds;
    },
    _findHistoricalMatchingMetrics: function _findHistoricalMatchingMetrics(queues, metricName, startTime, handler) {
      try {
        var queueData = this.get('realtimeService').get("queueData");
        if (moment(startTime) > moment().startOf("day").utc()) {
          queueData = this.get('realtimeService').get("intervalQueueData");
        }
        if (queueData === null || typeof queueData === "undefined") {
          return;
        }
        for (var x = 0; x < queueData.results.length; x++) {
          var results = queueData.results[x];
          if (Object.keys(results.group).length >= 1 && queues.indexOf(results.group.queueId) > -1) {
            for (var y = 0; y < results.data.length; y++) {
              var data = results.data[y];
              if (data.interval.indexOf(startTime) === 0) {
                for (var i = 0; i < data.metrics.length; i++) {
                  var metric = data.metrics[i];
                  if (metric.metric === metricName) {
                    handler(metric.stats, results.group.queueId, queues, results.group.mediaType);
                  }
                }
              }
            }
          }
        }
      } catch (ex) {
        Ember.Logger.error(ex);
      }
    },
    _findHistoricalAgentMatchingMetrics: function _findHistoricalAgentMatchingMetrics(agentId, queues, metricName, handler) {
      try {
        var agentData = this.get('realtimeService').get("agentData");
        if (agentData === null || typeof agentData === "undefined") {
          return;
        }
        for (var x = 0; x < agentData.results.length; x++) {
          var results = agentData.results[x];
          if (Object.keys(results.group).length >= 1 && queues.indexOf(results.group.queueId) > -1 && results.group.userId === agentId) {
            for (var y = 0; y < results.data.length; y++) {
              var data = results.data[y];
              for (var i = 0; i < data.metrics.length; i++) {
                var metric = data.metrics[i];
                if (metric.metric === metricName) {
                  handler(metric.stats, results.group.queueId, agentId, queues, results.group.mediaType);
                }
              }
            }
          }
        }
      } catch (ex) {
        Ember.Logger.error(ex);
      }
    },
    _findMatchingObservationMetrics: function _findMatchingObservationMetrics(queues, metric, handler) {
      try {
        var observationData = this.get('realtimeService').get("observationData");
        var queueIds = Object.keys(observationData);
        for (var x = 0; x < queueIds.length; x++) {
          var queueId = queueIds[x];
          if (queues.indexOf(queueId) > -1) {
            var groupKeys = Object.keys(observationData[queueId]);
            for (var i = 0; i < groupKeys.length; i++) {
              var group = groupKeys[i];
              if (observationData[queueId][group][metric]) {
                handler(observationData[queueId][group][metric], group);
              }
            }
          }
        }
      } catch (ex) {
        Ember.Logger.error(ex);
      }
    },
    getOnQueueUsers: function getOnQueueUsers(queues) {
      var idleValue = 0;
      var interactingValue = 0;
      this._findMatchingObservationMetrics(queues, "oUserRoutingStatuses.IDLE", function (data) {
        idleValue = idleValue + data.count;
      });
      this._findMatchingObservationMetrics(queues, "oUserRoutingStatuses.INTERACTING", function (data) {
        interactingValue = interactingValue + data.count;
      });
      this._findMatchingObservationMetrics(queues, "oUserRoutingStatuses.COMMUNICATING", function (data) {
        interactingValue = interactingValue + data.count;
      });
      return interactingValue + idleValue;
    },
    getStatDisplayName: function getStatDisplayName(statKey) {
      var txt = statKey;
      _widgetstattypes.default.forEach(function (stat) {
        if (stat.key === statKey) {
          txt = stat.text;
        }
      });
      _agentstattypes.default.forEach(function (stat) {
        if (stat.key === statKey) {
          txt = stat.text;
        }
      });
      return txt;
    },
    getStatValue: function getStatValue(queues, statKey, intervalStart, mediaType, agentId) {
      var returnData = {
        data: null,
        isTime: false,
        showPercent: false
      };
      try {
        if (statKey === "agent_status") {
          var result = [['Status', 'Agents']];
          returnData.data = result;
          return returnData;
        } else if (statKey === "conversations_waiting") {
          var value = 0;
          this._findMatchingObservationMetrics(queues, "oWaiting", function (data, statMediaType) {
            if (!mediaType || statMediaType.toLowerCase() === mediaType.toLowerCase() || mediaType.toLowerCase() === "all") {
              value = value + data.count;
              returnData.update = data.receivedAt;
            }
          });
          returnData.data = value;
          return returnData;
        } else if (statKey === "abandoned_interactions") {
          var _value = 0;
          this._findHistoricalMatchingMetrics(queues, "tAbandon", intervalStart, function (data, queueId, queues, statMediaType) {
            if (!mediaType || statMediaType.toLowerCase() === mediaType.toLowerCase() || mediaType.toLowerCase() === "all") {
              _value = _value + data.count;
              returnData.update = data.receivedAt;
            }
          });
          returnData.data = _value;
          return returnData;
        } else if (statKey === "conversations_active") {
          var _value2 = 0;
          this._findMatchingObservationMetrics(queues, "oInteracting", function (data, statMediaType) {
            if (!mediaType || statMediaType.toLowerCase() === mediaType.toLowerCase() || mediaType.toLowerCase() === "all") {
              _value2 = _value2 + data.count;
              returnData.update = data.receivedAt;
            }
          });
          returnData.data = _value2;
          return returnData;
        } else if (statKey === "agents_on_queue") {
          var _value3 = this.getOnQueueUsers(queues);
          returnData.data = _value3;
          return returnData;
        } else if (statKey === "agents_interacting" || statKey === "oUserRoutingStatuses.INTERACTING") {
          var _value4 = 0;
          this._findMatchingObservationMetrics(queues, "oUserRoutingStatuses.INTERACTING", function (data) {
            _value4 = _value4 + data.count;
            returnData.update = data.receivedAt;
          });
          returnData.data = _value4;
          return returnData;
        } else if (statKey === "agents_idle" || statKey === "oUserRoutingStatuses.IDLE") {
          var _value5 = 0;
          this._findMatchingObservationMetrics(queues, "oUserRoutingStatuses.IDLE", function (data) {
            _value5 = _value5 + data.count;
            returnData.update = data.receivedAt;
          });
          returnData.data = _value5;
          return returnData;
        } else if (statKey === "agents_not_responding" || statKey === "oUserRoutingStatuses.NOT_RESPONDING") {
          var _value6 = 0;
          this._findMatchingObservationMetrics(queues, "oUserRoutingStatuses.NOT_RESPONDING", function (data) {
            _value6 = _value6 + data.count;
            returnData.update = data.receivedAt;
          });
          returnData.data = _value6;
          return returnData;
        } else if (statKey === "queue_depth") {
          var agentsOnQueue = this.getOnQueueUsers(queues);
          if (agentsOnQueue === 0) {
            returnData.data = "-";
          } else {
            var conversationsWaiting = 0;
            this._findMatchingObservationMetrics(queues, "oWaiting", function (data, statMediaType) {
              if (!mediaType || statMediaType.toLowerCase() === mediaType.toLowerCase() || mediaType.toLowerCase() === "all") {
                conversationsWaiting = conversationsWaiting + data.count;
              }
            });
            if (agentsOnQueue === 0) {
              returnData.data = "-";
            } else {
              returnData.data = parseFloat(conversationsWaiting / agentsOnQueue + 0.001).toFixed(2);
            }
          }
          return returnData;
        } else if (statKey === "campaign_contactrate") {
          returnData.data = "-";
          var statData = this.get('realtimeService').get("campaignStats");
          if (typeof statData === "undefined" || statData === null) {
            return returnData;
          }
          var attempts = 0;
          var connects = 0;
          queues.forEach(function (campaignId) {
            var data = statData[campaignId];
            if (data && data.contactRate) {
              attempts = attempts + data.contactRate.attempts;
              connects = connects + data.contactRate.connects;
            }
          });
          if (attempts > 0) {
            var contactRate = connects / attempts;
            returnData.data = parseFloat((contactRate * 100).toFixed(0));
            returnData.showPercent = true;
          } else {
            returnData.data = '-';
          }
          return returnData;
        } else if (statKey === "campaign_progress") {
          var progressData = this.get('realtimeService').get("campaignProgress");
          if (typeof progressData === "undefined" || progressData === null) {
            return returnData;
          }
          queues.forEach(function (campaignId) {
            var data = progressData[campaignId];
            if (data && data.percentage) {
              returnData.data = data.percentage;
            } else {
              returnData.data = 0;
            }
            returnData.showPercent = true;
          });
          return returnData;
        } else if (statKey === "campaign_idleagents") {
          returnData.data = "0";
          var _statData = this.get('realtimeService').get("campaignStats");
          if (typeof _statData === "undefined" || _statData === null) {
            return returnData;
          }
          queues.forEach(function (campaignId) {
            var data = _statData[campaignId];
            if (data) {
              returnData.data = data.idleAgents;
            }
          });
          return returnData;
        } else if (statKey === "sla_today") {
          var numerator = 0;
          var denominator = 0;
          this._findHistoricalMatchingMetrics(queues, "oServiceLevel", intervalStart, function (data, queueId, queues, statMediaType) {
            if (!mediaType || statMediaType.toLowerCase() === mediaType.toLowerCase() || mediaType.toLowerCase() === "all") {
              numerator = numerator + data.numerator;
              denominator = denominator + data.denominator;
            }
          });
          if (denominator === 0) {
            returnData.data = '-';
            returnData.showPercent = false;
          } else {
            returnData.data = parseFloat((numerator / denominator * 100).toFixed(2));
            returnData.showPercent = true;
          }
          return returnData;
        } else if (statKey === "total_conversations") {
          var _value7 = 0;
          this._findHistoricalMatchingMetrics(queues, "nOffered", intervalStart, function (data, queueId, queues, statMediaType) {
            if (!mediaType || statMediaType.toLowerCase() === mediaType.toLowerCase() || mediaType.toLowerCase() === "all") {
              _value7 = _value7 + data.count;
            } else if (!statMediaType) {
              debugger;
            }
          });
          returnData.data = _value7;
          return returnData;
        } else if (statKey === "total_conversations_answered") {
          var _value8 = 0;
          this._findHistoricalMatchingMetrics(queues, "tAnswered", intervalStart, function (data, queueId, queues, statMediaType) {
            if (!mediaType || statMediaType.toLowerCase() === mediaType.toLowerCase() || mediaType.toLowerCase() === "all") {
              _value8 = _value8 + data.count;
            }
          });
          returnData.data = _value8;
          return returnData;
        }
        //////////////////////////////
        else if (statKey === "average_handle_time") {
          var _value9 = 0;
          var count = 0;
          this._findHistoricalMatchingMetrics(queues, "tHandle", intervalStart, function (data, queueId, queues, statMediaType) {
            if (!mediaType || statMediaType.toLowerCase() === mediaType.toLowerCase() || mediaType.toLowerCase() === "all") {
              _value9 = _value9 + data.sum;
              count = count + data.count;
            }
          });
          if (count === 0) {
            returnData.data = '-';
            returnData.isTime = false;
          } else {
            var avg = _value9 / count;
            returnData.data = this._formatTimeString(avg);
            returnData.isTime = true;
          }
          return returnData;
        } else if (statKey === "average_hold_time") {
          var _value10 = 0;
          var _count = 0;
          this._findHistoricalMatchingMetrics(queues, "tHeldComplete", intervalStart, function (data, queueId, queues, statMediaType) {
            if (!mediaType || statMediaType.toLowerCase() === mediaType.toLowerCase() || mediaType.toLowerCase() === "all") {
              _value10 = _value10 + data.sum;
              _count = _count + data.count;
            }
          });
          if (_count === 0) {
            returnData.data = '-';
            returnData.isTime = false;
          } else {
            var _avg = _value10 / _count;
            returnData.data = this._formatTimeString(_avg);
            returnData.isTime = true;
          }
          return returnData;
        } else if (statKey === "average_acw_time") {
          var _value11 = 0;
          var _count2 = 0;
          this._findHistoricalMatchingMetrics(queues, "tAcw", intervalStart, function (data, queueId, queues, statMediaType) {
            if (!mediaType || statMediaType.toLowerCase() === mediaType.toLowerCase() || mediaType.toLowerCase() === "all") {
              _value11 = _value11 + data.sum;
              _count2 = _count2 + data.count;
            }
          });
          if (_count2 === 0) {
            returnData.data = '-';
            returnData.isTime = false;
          } else {
            var _avg2 = _value11 / _count2;
            returnData.data = this._formatTimeString(_avg2);
            returnData.isTime = true;
          }
          return returnData;
        } else if (statKey === "total_conversations_answered_pct") {
          var offered = 0;
          this._findHistoricalMatchingMetrics(queues, "nOffered", intervalStart, function (data, queueId, queues, statMediaType) {
            if (!mediaType || statMediaType.toLowerCase() === mediaType.toLowerCase() || mediaType.toLowerCase() === "all") {
              offered = offered + data.count;
            }
          });
          var answered = 0;
          this._findHistoricalMatchingMetrics(queues, "tAnswered", intervalStart, function (data, queueId, queues, statMediaType) {
            if (!mediaType || statMediaType.toLowerCase() === mediaType.toLowerCase() || mediaType.toLowerCase() === "all") {
              answered = answered + data.count;
            }
          });
          if (offered > 0) {
            var answeredPct = answered / offered;
            returnData.data = parseFloat((answeredPct * 100).toFixed(0));
            returnData.showPercent = true;
          } else {
            returnData.data = '-';
          }
          return returnData;
        } else if (statKey === "average_wait_time") {
          var _value12 = 0;
          var _count3 = 0;
          this._findHistoricalMatchingMetrics(queues, "tAcd", intervalStart, function (data, queueId, queues, statMediaType) {
            if (!mediaType || statMediaType.toLowerCase() === mediaType.toLowerCase() || mediaType.toLowerCase() === "all") {
              _value12 = _value12 + data.sum;
              _count3 = _count3 + data.count;
            }
          });
          if (_count3 === 0) {
            returnData.data = '-';
            returnData.isTime = false;
          } else {
            var _avg3 = _value12 / _count3;
            returnData.data = this._formatTimeString(_avg3);
            returnData.isTime = true;
          }
          return returnData;
        } else if (statKey === "average_speed_of_answer") {
          var _value13 = 0;
          var _count4 = 0;
          this._findHistoricalMatchingMetrics(queues, "tAnswered", intervalStart, function (data, queueId, queues, statMediaType) {
            if (!mediaType || statMediaType.toLowerCase() === mediaType.toLowerCase() || mediaType.toLowerCase() === "all") {
              _value13 = _value13 + data.sum;
              _count4 = _count4 + data.count;
            }
          });
          if (_count4 === 0) {
            returnData.data = '-';
            returnData.isTime = false;
          } else {
            var _avg4 = _value13 / _count4;
            returnData.data = this._formatTimeString(_avg4);
            returnData.isTime = true;
          }
          return returnData;
        } else if (statKey === "average_call_time") {
          var _value14 = 0;
          var _count5 = 0;
          this._findHistoricalMatchingMetrics(queues, "tTalkComplete", intervalStart, function (data, queueId, queues, statMediaType) {
            if (!mediaType || statMediaType.toLowerCase() === mediaType.toLowerCase() || mediaType.toLowerCase() === "all") {
              _value14 = _value14 + data.sum;
              _count5 = _count5 + data.count;
            }
          });
          var _avg5 = _value14 / _count5;
          returnData.data = this._formatTimeString(_avg5);
          returnData.isTime = true;
          return returnData;
        } else if (statKey === "queue_conversation_state_by_mediatype") {
          var mediaTypes = ['voice', 'chat', 'email', 'callback', 'message'];
          var _result = [['Media Type', 'Waiting', 'Interacting']];
          var counts = {};
          for (var x = 0; x < mediaTypes.length; x++) {
            counts[mediaTypes[x]] = {
              interacting: 0,
              waiting: 0
            };
          }
          this._findMatchingObservationMetrics(queues, "oInteracting", function (data, mediaType) {
            if (counts[mediaType]) {
              counts[mediaType].interacting = data.count;
            }
          });
          this._findMatchingObservationMetrics(queues, "oWaiting", function (data, mediaType) {
            if (counts[mediaType]) {
              counts[mediaType].waiting = data.count;
            }
          });
          for (var _i = 0, _mediaTypes = mediaTypes; _i < _mediaTypes.length; _i++) {
            var _x = _mediaTypes[_i];
            if (counts[_x]) {
              _result.push([_x, counts[_x].waiting, counts[_x].interacting]);
            }
          }
          returnData.data = _result;
          return returnData;
        } else if (statKey === "queue_mediatype") {
          var _mediaTypes2 = ['voice', 'chat', 'email', 'callback', 'message'];
          var _result2 = [['Media', 'Count']];
          var _counts = {};
          this._findMatchingObservationMetrics(queues, "oInteracting", function (data, mediaType) {
            if (typeof _counts[mediaType] === "undefined") {
              _counts[mediaType] = 0;
            }
            _counts[mediaType] = _counts[mediaType] + data.count;
          });
          this._findMatchingObservationMetrics(queues, "oWaiting", function (data, mediaType) {
            if (typeof _counts[mediaType] === "undefined") {
              _counts[mediaType] = 0;
            }
            _counts[mediaType] = _counts[mediaType] + data.count;
          });
          var countKeys = Object.keys(_counts);
          for (var _x2 = 0; _x2 < countKeys.length; _x2++) {
            var key = countKeys[_x2];
            if (_mediaTypes2.indexOf(key) > -1) {
              _result2.push([key, _counts[key]]);
            }
          }
          returnData.data = _result2;
          return returnData;
        } else if (statKey === "longest_call_waiting") {
          var lcw = null;
          this._findMatchingObservationMetrics(queues, "oWaitingObservations", function (data, statMediaType) {
            if (!mediaType || statMediaType.toLowerCase() === mediaType.toLowerCase() || mediaType.toLowerCase() === "all") {
              if (!data || data.length === 0) {
                return;
              }
              data.forEach(function (observation) {
                var time = moment(observation.observationDate);
                if (lcw === null || lcw > time) {
                  lcw = time;
                }
              });
            }
          });
          returnData.isTimeSince = true;
          returnData.isTime = true;
          returnData.data = lcw;
          return returnData;
        } else if (statKey === "calls_waiting_list") {
          var calls = [];
          this._findMatchingObservationMetrics(queues, "oWaitingObservations", function (data) {
            if (!data || data.length === 0) {
              return;
            }
            data.forEach(function (observation) {
              calls.push(observation);
            });
          });
          returnData.data = calls;
          return returnData;
        } else if (statKey === "agent_answered") {
          var _value15 = 0;
          this._findHistoricalAgentMatchingMetrics(agentId, queues, "tAnswered", function (data) {
            _value15 = _value15 + data.count;
          });
          returnData.data = _value15;
          return returnData;
        } else if (statKey === "agent_average_handle") {
          var _value16 = 0;
          var _count6 = 0;
          this._findHistoricalAgentMatchingMetrics(agentId, queues, "tHandle", function (data) {
            _value16 = _value16 + data.sum;
            _count6 = _count6 + data.count;
          });
          var _avg6 = _value16 / _count6;
          returnData.data = this._formatTimeString(_avg6);
          returnData.isTime = true;
          return returnData;
        } else if (statKey === "agent_average_acw") {
          var _value17 = 0;
          var _count7 = 0;
          this._findHistoricalAgentMatchingMetrics(agentId, queues, "tAcw", function (data) {
            _value17 = _value17 + data.sum;
            _count7 = _count7 + data.count;
          });
          var _avg7 = 0;
          if (_count7 > 0) {
            _avg7 = _value17 / _count7;
          }
          returnData.data = this._formatTimeString(_avg7);
          returnData.isTime = true;
          return returnData;
        } else if (statKey === "agent_avg_hold_time") {
          var _value18 = 0;
          var _count8 = 0;
          this._findHistoricalAgentMatchingMetrics(agentId, queues, "tHeldComplete", function (data) {
            _value18 = _value18 + data.sum;
            _count8 = _count8 + data.count;
          });
          var _avg8 = 0;
          if (_count8 > 0) {
            _avg8 = _value18 / _count8;
          }
          returnData.data = this._formatTimeString(_avg8);
          returnData.isTime = true;
          return returnData;
        } else if (statKey === "agent_hold_time") {
          var _value19 = 0;
          this._findHistoricalAgentMatchingMetrics(agentId, queues, "tHeldComplete", function (data) {
            _value19 = _value19 + data.sum;
          });
          returnData.data = this._formatTimeString(_value19);
          return returnData;
        } else if (statKey === "agent_avg_talk_time") {
          var _value20 = 0;
          var _count9 = 0;
          this._findHistoricalAgentMatchingMetrics(agentId, queues, "tTalkComplete", function (data) {
            _value20 = _value20 + data.sum;
            _count9 = _count9 + data.count;
          });
          var _avg9 = 0;
          if (_count9 > 0) {
            _avg9 = _value20 / _count9;
          }
          returnData.data = this._formatTimeString(_avg9);
          returnData.isTime = true;
          return returnData;
        } else if (statKey === "agent_talk_time") {
          var _value21 = 0;
          this._findHistoricalAgentMatchingMetrics(agentId, queues, "tTalkComplete", function (data) {
            _value21 = _value21 + data.sum;
          });
          returnData.data = this._formatTimeString(_value21);
          return returnData;
        } else if (statKey === "agent_transfer") {
          var _value22 = 0;
          this._findHistoricalAgentMatchingMetrics(agentId, queues, "nTransferred", function (data) {
            _value22 = _value22 + data.count;
          });
          returnData.data = _value22;
          return returnData;
        } else if (statKey === "outbound_calls") {
          var _value23 = 0;
          this._findHistoricalAgentMatchingMetrics(agentId, queues, "nOutbound", function (data) {
            _value23 = _value23 + data.count;
          });
          returnData.data = _value23;
          return returnData;
        } else {
          var _value24 = 0;
          this._findMatchingObservationMetrics(queues, statKey, function (data) {
            _value24 = _value24 + data.count;
          });
          returnData.data = _value24;
          return returnData;
        }
      } catch (ex) {
        this.get("requestService").logError("statCalculation: " + JSON.stringify(ex));
        Ember.Logger.error(ex);
        return returnData;
      }
      //  });
    }
  });
});